export default {
  methods: {
    emitError(error) {
      let errorMessage = error;

      if (typeof(errorMessage) === 'undefined' || errorMessage == null) {
        errorMessage = 'Sorry, an error occurred. Please try later or contact your administrator.';
      }
      this.$notify({
        title: `<span>Error</span> <button class="custom-close-btn" onclick="this.closest('.vue-notification').remove()">x</button>`,
        text: errorMessage,
        type: 'error',
        duration: 5500,
      });
    },
    emitSuccess(success) {
      this.$notify({
        title: `<span>Success</span> <button class="custom-close-btn" onclick="this.closest('.vue-notification').remove()">x</button>`,
        text: success,
        type: 'success',
        duration: 5500,
      });
    },
    emitWarning(warning) {
      this.$notify({
        title: `<span>Warning</span> <button class="custom-close-btn" onclick="this.closest('.vue-notification').remove()">x</button>`,
        text: warning,
        type: 'warn',
        duration: 5500,
      });
    },
  },
};
